<template>
  <v-theme-provider>
    <base-section
      id="career-group"
      space="40"
    >
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <base-section-heading :title="title" />
            <base-body space="0">
               <v-container fluid>
                <v-row dense>
                  <v-col
                    v-for="data in posts"
                    :key="data.web_career_id"
                    md="12"
                    xs="12"
                  >
                    <v-card>
                      <v-card-actions>
                        <span @click="Detail(data.web_career_id)" class="cursor">
                        {{ data.web_career_name }} ({{ data.web_career_province_name}})</span>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="Share(data.web_career_id)">
                          <v-icon>mdi-whatsapp</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </base-body>
          </v-col>
        </v-row>
        <template v-for="data in careergroup">
          <v-btn :key="data.web_career_category_id" class="ma-2" outlined>
            {{data.web_career_category_name}}
          </v-btn>
        </template>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionCareersGroup',
    data: () => ({
            title: 'Berikut Karir Anda',
            posts: [],
            careergroup: [
                {web_career_category_id : 'insan', web_career_category_name: 'Takaful Karyawan', web_career_category_count: 0},
                {web_career_category_id : 'agen', web_career_category_name: 'Takaful Marketing Executive', web_career_category_count: 0},
                {web_career_category_id : 'magang', web_career_category_name: 'Takaful Magang', web_career_category_count: 0},
            ],
        }),
    created () {
        this.List()
    },
    methods: {
        List () {
          this.loading  = true
          let category  = this.$router.currentRoute.path
          category      = category.replace('/karir-', '')
          console.log(category)
          let formdata = {
                web_career_id: '',
                web_career_category: category,
                web_career_actived: 'true'
          }
          let param      = this.$functions.ParamPOST(formdata)
          this.$axios.post(this.$functions.UrlPOST('apiWebCareerList'),param,{
          headers: {
              'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
          })
          .then(response => {
              this.posts = response.data
              this.loading  = false
          })
          .catch(e => {
              alert(e)
              this.loading  = false
          })
        },
        MaxLength (content, max) {
          let returns = content
          if (content.length >= max) {
            returns = content.substr(0,max) + '... '
          }
          return returns
        },
        Share (id) {
          let paramurl = this.$functions.AppURL() +'/karir-detail/'+id
          window.open('https://wa.me/?text='+encodeURIComponent(paramurl))
        },
        Detail (url) {
          this.$router.push({name: 'karir-detail', params: { url: url }})
        },
    }
  }
</script>
